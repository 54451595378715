import {defineStore} from "pinia";

export const useClientWidthStore = defineStore({
    id: 'clientWidthStore',
    state: () => (
        {
            width: 0
        }
    ),
    actions: {
        setClientWidth(width) {
            this.width = width
        },
        getClientWidth() {
            return this.width
        }
    }
})