<template>
  <keep-alive>
    <router-view />
  </keep-alive>
</template>
<script setup>

import {useRootFontSizeStore} from "@/stores/rootFontSizeStore";
import {useClientWidthStore} from "@/stores/clientWidth";

const {setFontSize} = useRootFontSizeStore();

const {setClientWidth} = useClientWidthStore();


const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};



(function () {
  const baseSize = 1; // 32
  function setRem() {
    let width = document.documentElement.clientWidth
    setClientWidth(width)
    if (width >= 1440) {
      width = 1920;
    }
    let scale = 0;
    if (width <= 768) {
      //  width = width * 393/768;
      scale = width / 393;
    } else {
      scale = width / 1920; // 750

    }
    setFontSize(baseSize * Math.min(scale, 2))
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + "px";
  }

  setRem();

  window.onresize = function () {
    setRem();
  };
})();

</script>
<style>
html, body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: PingFang SC, PingFang SC;
}

* {
  box-sizing: border-box;
}
</style>