export const PCRoutes = [
    {
        path: '/DashBoard',
        name: 'Home',
        component: () => import('@/views/PC/DashBoard/DashBoard.vue'),
        meta: {
            isHome: true
        }
    },
    {
        path: '/InfoChannel',
        name: "InfoChannel",
        component: () => import('@/views/PC/InfoChannel/InfoChannel.vue'),

    },
    {
        path: '/Digitalize',
        name: "Digitalize",
        component: () => import('@/views/PC/Digitalize/DigitalizeView.vue'),

    },
    {
        path: '/SiliconIndustry',
        name: "SiliconIndustry",
        component: () => import('@/views/PC/SiliconIndustry/SiliconIndustry.vue'),

    },
    {
        path: '/IntellectualProperty',
        name: "IntellectualProperty",
        component: () => import('@/views/PC/IntellectualProperty/IntellectualProperty.vue'),

    },
    {
        path: '/About',
        name: "About",
        component: () => import('@/views/PC/About/AboutView.vue'),

    },
    {
        path: '/CompanyTrends/:index?',
        name: "CompanyTrends",
        component: () => import('@/views/PC/CompanyTrends/TrendsView.vue'),
    },
    {
        path: '/CompanyTrends/ArticleDetails',
        name: "ArticleDetails",
        component: () => import('@/views/PC/CompanyTrends/modules/ArticleDetails.vue'),

    },
    {
        path: '/ArticleDetail/:category/:id',
        name: "ArticleDetail",
        component: () => import('@/views/PC/ArticleDetail/ArticleDetail.vue'),

    },
]