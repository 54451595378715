export const MobileRoutes = [
    {
        path: '/M/DashBoard',
        name: 'M-Home',
        component: () => import('@/views/Mobile/DashBoard/DashBoard.vue'),
        meta: {
            isHome: true
        }
    },
    {
        path: '/M/InfoChannel',
        name: "M-InfoChannel",
        component: () => import('@/views/Mobile/InfoChannel/InfoChannel.vue'),

    },
    {
        path: '/M/Digitalize',
        name: "M-Digitalize",
        component: () => import('@/views/Mobile/Digitalize/DigitalizeView.vue'),

    },
    {
        path: '/M/SiliconIndustry',
        name: "M-SiliconIndustry",
        component: () => import('@/views/Mobile/SiliconIndustry/SiliconIndustry.vue'),

    },
    {
        path: '/M/IntellectualProperty',
        name: "M-IntellectualProperty",
        component: () => import('@/views/Mobile/IntellectualProperty/IntellectualProperty.vue'),

    },
    {
        path: '/M/About',
        name: "M-About",
        component: () => import('@/views/Mobile/About/AboutView.vue'),

    },
    {
        path: '/M/CompanyTrends',
        name: "M-CompanyTrends",
       // redirect: '/M/CompanyTrendsAlive',
        component: () => import('@/views/Mobile/CompanyTrends/TrendsView.vue'),

    },


    {
        path: '/M/CompanyTrends/ArticleDetails',
        name: "M-ArticleDetails",
        component: () => import('@/views/Mobile/CompanyTrends/modules/ArticleDetails.vue'),

    },
    {
        path: '/M/ArticleDetail/:category/:id',
        name: "M-ArticleDetail",
        component: () => import('@/views/Mobile/ArticleDetail/ArticleDetail.vue'),

    },
]