import {createRouter, createWebHistory} from 'vue-router'
import {PCRoutes} from "@/router/PCRoutes";
import {MobileRoutes} from "@/router/MobileRoutes";
const routes = [
    {
        path: '/',
        redirect: '/DashBoard',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            ...PCRoutes,

        ]
    },
    {
        path: '/m',
        redirect: '/M-DashBoard',
    },
    {
        path: '/M',
        redirect: '/M-DashBoard',
        component: () => import('@/layouts/MobileLayout.vue'),
        children: [
            ...MobileRoutes
        ]
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to,from,savePosition) {
        if(to && from && (to.name == 'CompanyTrends' || to.name == 'M-CompanyTrends'))
        {
            if(savePosition){
                return savePosition;
            }else{
                return { left: 0, top: 0 }
            }
        }
        else{
            return { left: 0, top: 0 }
        }
    }
})

export default router
