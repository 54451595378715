import {defineStore} from "pinia";

export const useRootFontSizeStore = defineStore({
    id: 'rootFontSizeStore',
    state: () => (
        {
            fontSize: 0
        }
    ),
    actions: {
        setFontSize(fontSize) {
            this.fontSize = fontSize
        },
        getFontSize() {
            return this.fontSize
        }
    }
})